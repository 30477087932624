import {useState} from 'react';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import Colors from '../ressources/Colors';

const SwitchBox = ({sendIsDarkModeToParent, isDark}) => {

    const [isOn, setIsOn] = useState(isDark);

    const handleClick = () => {
        console.log("isOn is "+!isOn+ "inside handleClick")
        sendIsDarkModeToParent(!isOn);
    }

    return(
        <FormGroup>
            <FormControlLabel 
                style={{color: isOn ? Colors.get().white : Colors.get().black}} 
                label="Darkmode"
                size="small"
                control={<Switch defaultChecked={isDark} />} 
                onClick={() => {
                    console.log("IsOn is: "+isOn+ " now setting it to "+!isOn)
                    setIsOn(!isOn);
                    handleClick();
                }}
            />
        </FormGroup>
    );
}

export default SwitchBox;
import { ResponsiveBar } from '@nivo/bar'

import Colors from '../ressources/Colors';

//Bar data
//import {getEurLexData} from '../ressources/EurLexData';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyBar = ({ data, which, isDarkMode, lineGraphSettings }) => (

    <div style={{height: '97%', backgroundColor: isDarkMode ? Colors.get().widget_backg_dark : Colors.get().widget_backg_light}}>
        <ResponsiveBar
            layout="horizontal"
            data={data}
            keys={[
                'value',
            ]}
            indexBy="country"
            //To be completly visible
            margin={{ top: 0, right: 10, bottom: 50, left: 90 }}
            
            //Impacts the width of a single bar (0.0 = no space between bars)
            padding={0.3}
    
            colors="#998491"
            
            theme = {lineGraphSettings.theme}
            
            //All the possible axis
            axisBottom={{
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 0,
                legend: which === 'decision' ? 'Court Decisions' : 'Court Decisions',//'years',
                legendPosition: 'middle',
                legendOffset: 35,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: 'countries',
                legendPosition: 'middle',
                legendOffset: -80
            }}
    
            //Number color on Bar's
            labelTextColor= {isDarkMode ? Colors.get().white : Colors.get().black}

    
            defs={[
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                },
            ]}
            fill={[
                {
                    match: {
                        id: 'ttt'
                    },
                    id: 'lines'
                },
            ]}
        />
    </div>
)

export default MyBar;
export const countryLookup = [
 {
   "country_en": "Austria",
   "country_de": "Österreich",
   "twoLetterCode": "AT",
   "threeLetterCode": "AUT",
   "inhabitants": 9
 },
 {
   "country_en": "Belgium",
   "country_de": "Belgien",
   "twoLetterCode": "BE",
   "threeLetterCode": "BEL",
   "inhabitants": 11.6
 },
 {
   "country_en": "Bulgaria",
   "country_de": "Bulgarien",
   "twoLetterCode": "BG",
   "threeLetterCode": "BGR",
   "inhabitants": 6.8
 },
 {
   "country_en": "Croatia",
   "country_de": "Kroatien",
   "twoLetterCode": "HR",
   "threeLetterCode": "HRV",
   "inhabitants": 3.8
 },
 {
   "country_en": "Cyprus",
   "country_de": "Zypern",
   "twoLetterCode": "CY",
   "threeLetterCode": "CYP",
   "inhabitants": 1.2
 },
 {
   "country_en": "Czechia",
   "country_de": "Tschechien",
   "twoLetterCode": "CZ",
   "threeLetterCode": "CZE",
   "inhabitants": 10.5
 },
 {
   "country_en": "Denmark",
   "country_de": "Dänemark",
   "twoLetterCode": "DK",
   "threeLetterCode": "DNK",
   "inhabitants": 5.9
 },
 {
   "country_en": "Estonia",
   "country_de": "Estland",
   "twoLetterCode": "EE",
   "threeLetterCode": "EST",
   "inhabitants": 1.3
 },
 {
   "country_en": "Finland",
   "country_de": "Finnland",
   "twoLetterCode": "FI",
   "threeLetterCode": "FIN",
   "inhabitants": 5.6
 },
 {
   "country_en": "France",
   "country_de": "Frankreich",
   "twoLetterCode": "FR",
   "threeLetterCode": "FRA",
   "inhabitants": 65.8
 },
 {
   "country_en": "Germany",
   "country_de": "Deutschland",
   "twoLetterCode": "DE",
   "threeLetterCode": "DEU",
   "inhabitants": 83.3
 },
 {
   "country_en": "Greece",
   "country_de": "Griechenland",
   "twoLetterCode": "GR",
   "threeLetterCode": "GRC",
   "inhabitants": 10.6
 },
 {
   "country_en": "Hungary",
   "country_de": "Ungarn",
   "twoLetterCode": "HU",
   "threeLetterCode": "HUN",
   "inhabitants": 9.7
 },
 {
   "country_en": "Ireland",
   "country_de": "Irland",
   "twoLetterCode": "IE",
   "threeLetterCode": "IRL",
   "inhabitants": 5.1
 },
 {
   "country_en": "Italy",
   "country_de": "Italien",
   "twoLetterCode": "IT",
   "threeLetterCode": "ITA",
   "inhabitants": 58.9
 },
 {
   "country_en": "Latvia",
   "country_de": "Lettland",
   "twoLetterCode": "LV",
   "threeLetterCode": "LVA",
   "inhabitants": 1.9
 },
 {
   "country_en": "Lithuania",
   "country_de": "Litauen",
   "twoLetterCode": "LT",
   "threeLetterCode": "LTU",
   "inhabitants": 2.8
 },
 {
   "country_en": "Luxembourg",
   "country_de": "Luxemburg",
   "twoLetterCode": "LU",
   "threeLetterCode": "LUX",
   "inhabitants": 0.7
 },
 {
   "country_en": "Malta",
   "country_de": "Malta",
   "twoLetterCode": "MT",
   "threeLetterCode": "MLT",
   "inhabitants": 0.5
 },
 {
   "country_en": "Netherlands",
   "country_de": "Niederlande",
   "twoLetterCode": "NL",
   "threeLetterCode": "NLD",
   "inhabitants": 17.7
 },
 {
   "country_en": "Poland",
   "country_de": "Polen",
   "twoLetterCode": "PL",
   "threeLetterCode": "POL",
   "inhabitants": 38
 },
 {
   "country_en": "Portugal",
   "country_de": "Portugal",
   "twoLetterCode": "PT",
   "threeLetterCode": "PRT",
   "inhabitants": 10.3
 },
 {
   "country_en": "Romania",
   "country_de": "Rumänien",
   "twoLetterCode": "RO",
   "threeLetterCode": "ROU",
   "inhabitants": 19
 },
 {
   "country_en": "Slovakia",
   "country_de": "Slowakei",
   "twoLetterCode": "SK",
   "threeLetterCode": "SVK",
   "inhabitants": 5.4
 },
 {
   "country_en": "Slovenia",
   "country_de": "Slowenien",
   "twoLetterCode": "SI",
   "threeLetterCode": "SVN",
   "inhabitants": 2.1
 },
 {
   "country_en": "Spain",
   "country_de": "Spanien",
   "twoLetterCode": "ES",
   "threeLetterCode": "ESP",
   "inhabitants": 47.4
 },
 {
   "country_en": "Sweden",
   "country_de": "Schweden",
   "twoLetterCode": "SE",
   "threeLetterCode": "SWE",
   "inhabitants": 10.5
 }
];
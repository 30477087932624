import { ResponsiveChoropleth } from '@nivo/geo'

import {mapFeatures} from '../ressources/Mapfeatures';
import {q90} from '../helper/SomeMath';

import Colors from '../ressources/Colors';


// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

/**
 * Goes through all values and calculates the 90% quantile
 * which is used as 
 * @param {all countries with the occFreq} data 
 * @returns currently the 90% quantile
 */
function getDomain(data) {
    let values = [];
    data.forEach((entry) => {
       values.push(Number(entry.value));
    });
    
    return [0, q90(values)];
}

const MyChoropleth = ({ data, isDarkMode }) => (
    <div style={{height: '97%', background: isDarkMode ? Colors.get().grey : Colors.get().widget_backg_light}} >
        <ResponsiveChoropleth
            data={data}
            features={mapFeatures.features}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            colors='YlOrRd'
            domain={getDomain(data)}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            //zoom on world map
            projectionScale={800} //600
            //Position on x and y axis
            projectionTranslation={[ 0.3, 1.15 ]} //{[ 0.4, 1.43 ]}
            projectionRotation={[ 0, 0, 0 ]}
            enableGraticule={true}
            graticuleLineColor="#dddddd"
            borderWidth={0.5}
            borderColor="#152538"

            legends={[
                {
                    
                    anchor: 'bottom-left',
                    direction: 'column',
                    justify: true,
                    translateX: 20,
                    translateY: -100,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: 'left-to-right',
                    itemTextColor: isDarkMode ? Colors.get().white : Colors.get().black,
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000000',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    </div>
)

export default MyChoropleth;
import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Colors from '../ressources/Colors';

const LanguageSelectBox = ({languages, sendChoosenLanguageToParent, choosenLanguage, isDarkMode}) => {

    
    languages[languages.length] = {country: "All", value: 1};

    const [language, setLanguage] = React.useState(choosenLanguage);
    const handleChange = (event) => {
        setLanguage(event.target.value);
        sendSelection(event.target.value);
    };

    function sendSelection(value) {
        sendChoosenLanguageToParent(value);
    }


    return(
        <div>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}} >Language</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={language}
                    label="Language"
                    onChange={handleChange}
                    style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                    sx={{
                        color: isDarkMode ? Colors.get().white : Colors.get().black,
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                        },
                        '.MuiSvgIcon-root ': {
                          fill: isDarkMode ? "white !important" : "black !important",
                        }
                    }}
                >
                {languages.map((language, index) => {
                    return (
                        <MenuItem onClick={handleChange} key={index} value={language.country}>
                            {language.country}
                        </MenuItem>
                    )
                })}
            
                </Select>
            </FormControl>
        </div>
    );
}

export default LanguageSelectBox;
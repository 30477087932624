import { ResponsiveBar } from '@nivo/bar'

import Colors from '../ressources/Colors';

//Bar data
//import {getEurLexData} from '../ressources/EurLexData';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

//[2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021]}



const MultiCountryBar = ({ data, lineGraphSettings }) => (
    <ResponsiveBar
        layout="vertical"
        groupMode="grouped"
        data={data.countries}
        keys={data.keys}
        indexBy="country"
        //To be completly visible
        margin={{ top: 10, right: 130, bottom: 100, left: 150 }}
        
        //Impacts the width of a single bar (0.0 = no space between bars)
        padding={0.3}

        colors={{ scheme: 'purpleRed_green' }} //"#998491"

        theme = {lineGraphSettings.theme}
        //Number color on Bar's
        labelTextColor= {Colors.get().lightGrey}
        
        //All the possible axis
        axisBottom={{
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'countries',
            legendPosition: 'middle',
            legendOffset: 30
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Court Decisions',
            legendPosition: 'middle',
            legendOffset: -50,
            
        }}

        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

export default MultiCountryBar;
import React, {useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Colors from '../ressources/Colors';

export default function SelectBox({countries, sendChoosenCountriesToPartent, choosenCountries, isDarkMode}) {

    //1
    const [country, setCountry] = React.useState(choosenCountries[0]);
    const handleChange = (event) => {
        setCountry(event.target.value);
        sendSelection(event.target.value, 0);
    };

    //2
    const [country2, setCountry2] = React.useState(choosenCountries[1]);
    const handleChange2 = (event) => {
        setCountry2(event.target.value);
        sendSelection(event.target.value, 1);
    };

    //3
    const [country3, setCountry3] = React.useState(choosenCountries[2]);
    const handleChange3 = (event) => {
        setCountry3(event.target.value);
        sendSelection(event.target.value, 2);
    };

    //4
    const [country4, setCountry4] = React.useState(choosenCountries[3]);
    const handleChange4 = (event) => {
        setCountry4(event.target.value);
        sendSelection(event.target.value, 3);
    };

    //5
    const [country5, setCountry5] = React.useState(choosenCountries[4]);
    const handleChange5 = (event) => {
        setCountry5(event.target.value);
        sendSelection(event.target.value, 4);
    };



    function sendSelection(value, index) {
        //choosenCountries[index] = value;
        sendChoosenCountriesToPartent([index, value]);
    }

    const styles = theme => ({
        select: {
            '&:before': {
                borderColor: isDarkMode ? Colors.get().white : Colors.get().black,
            },
            '&:after': {
                borderColor: isDarkMode ? Colors.get().white : Colors.get().black,
            },
        },
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', marginRight: 5}}>
            
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}} >Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={country}
                        label="Age"
                        onChange={handleChange}
                        style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                        sx={{
                          color: isDarkMode ? Colors.get().white : Colors.get().black,
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: isDarkMode ? "white !important" : "black !important",
                          }
                        }}
                    >
                    {countries.map((country, index) => {
                        return (
                            <MenuItem onClick={handleChange} key={index} value={country}>
                                {country}
                            </MenuItem>
                        )
                    })}
                
                    </Select>
                </FormControl>
            

            <div style={{marginTop: 10}} />

            <div>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}>Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={country2}
                        label="Age"
                        onChange={handleChange2}
                        style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                        sx={{
                          color: isDarkMode ? Colors.get().white : Colors.get().black,
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: isDarkMode ? "white !important" : "black !important",
                          }
                        }}
                    >
                    {countries.map((country, index) => {
                        return (
                            <MenuItem key={index} value={country}>
                                {country}
                            </MenuItem>
                        )
                    })}
                
                    </Select>
                </FormControl>
            </div>

            <div style={{marginTop: 10}} />

            <div>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}>Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={country3}
                        label="Age"
                        onChange={handleChange3}
                        style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                        sx={{
                          color: isDarkMode ? Colors.get().white : Colors.get().black,
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: isDarkMode ? "white !important" : "black !important",
                          }
                        }}
                    >
                    {countries.map((country, index) => {
                        return (
                            <MenuItem key={index} value={country}>
                                {country}
                            </MenuItem>
                        )
                    })}
                
                    </Select>
                </FormControl>
            </div>

            <div style={{marginTop: 10}} />

            <div>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}>Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={country4}
                        label="Age"
                        onChange={handleChange4}
                        style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                        sx={{
                          color: isDarkMode ? Colors.get().white : Colors.get().black,
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: isDarkMode ? "white !important" : "black !important",
                          }
                        }}
                    >
                    {countries.map((country, index) => {
                        return (
                            <MenuItem key={index} value={country}>
                                {country}
                            </MenuItem>
                        )
                    })}
                
                    </Select>
                </FormControl>
            </div>


            <div style={{marginTop: 10}} />

            <div>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}>Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        value={country5}
                        label="Age"
                        onChange={handleChange5}
                        style={{color: isDarkMode ? Colors.get().white : Colors.get().black}}
                        sx={{
                            color: isDarkMode ? Colors.get().white : Colors.get().black,
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                            },
                            '.MuiSvgIcon-root ': {
                              fill: isDarkMode ? "white !important" : "black !important",
                            }
                          }}
                    >
                    {countries.map((country, index) => {
                        return (
                            <MenuItem key={index} value={country}>
                                {country}
                            </MenuItem>
                        )
                    })}
                
                    </Select>
                </FormControl>
            </div>

        </div>
        
    );
}
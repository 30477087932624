import React, {useState} from 'react';
import './App.css';
import MainPage from './components/MainPage';

//Images
import EUR from './ressources/images/europa.jpg';
import JURIS from './ressources/images/juris_logo_white.png';

//Ressources
import Colors from './ressources/Colors';


const PW = "Makrolog2023"; // "Makrolog2023"

const FILTER_WIDTH = 200;



const App = () => {
  //Password entering management
  const [pwEntered, setPwEntered] = useState(false);
  const [passw, setPassW] = useState("");
  const checkPW = e => {
    //console.log("e.value: "+e.target.value);
    setPassW(e.target.value);
    if (e.target.value === PW) {
      setPwEntered(true);
    }
  }

  const[isDarkmode, setIsDarkMode] = useState(true);
  const sendIsDarkModeToApp = (isDark) => {
    console.log("MainPage: set isDarkt to "+isDark);
    setIsDarkMode(isDark);
  }


  const Header = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', background: isDarkmode ? Colors.get().grey : Colors.get().white}}>
        <div style={{height: 80, width: FILTER_WIDTH, background: '#101b87', textAlign: 'center'}} >
          <img style={{}} height={80} src={EUR}/>
        </div>
        <div style={{marginLeft: 50, marginTop: 7}} >
          <a href onClick={() => window.open("https://www.juris.de/jportal/nav/index.jsp#/")} >
            <img style={{}} height={65} src={JURIS}/>
          </a>
        </div>
      </div>
    );
  }

  return (
    pwEntered ?
      <div style={{height: '100vh', width: '100vw'}}>
        {/* Header europe & juris logo*/}
        <Header/>
          {/* Main Page */}
          <div style={{background: isDarkmode ? Colors.get().background : Colors.get().white, height: '100%', width: '100%'}}>
            <MainPage sendIsDarkModeToApp={sendIsDarkModeToApp}/>
          </div>
      </div>
    :
      <div style={{display: 'flex', flexDirection: 'column', marginLeft: '45%', marginTop: '20%'}} >
        {/* Ask whoever loads this site to enter the pw */}
        <p >Enter Password: </p>
        <form>
          <label>
            <input type="password" value={passw} onChange={checkPW}/>
          </label>
        </form>
      </div>

   );
};

export default App;
//Necessary imports
import * as React from 'react';
import Slider from '@mui/material/Slider';

//Own stuff
import Globals from '../Globals';
//-------------- import end --------------

//required for the slider to work
function valuetext(value) {
    return `${value}`;
}

export default function RangeSlider({sendDataToParent, timeSpan}) {
    //value is not displayed in here, but required for the slider to work
    const [value, setValue] = React.useState([timeSpan[0], timeSpan[1]]);

    const handleChange = (event, newValue) => {
        //Call parent function to set current timeSpan values
        sendDataToParent(newValue);
        //required for the slider to work
        setValue(newValue);
    };

    return (
        <div style={{marginLeft: 10, marginRight: 10}}>
            <Slider
                getAriaLabel={() => 'Time range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={Globals.minYear}
                max={Globals.maxYear}
                size='medium'
                color='primary'
            />
        </div>
    );
}
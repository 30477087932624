import React, {useState} from 'react';

import Globals from '../Globals';

import Colors from '../ressources/Colors';

import MyChoropleth from './MyChoropleth';
//import TabGroup from './TabGroup';
import MultiCountryBar from './MultiCountryBar';
import RangeSlider from './RangeSlider';
import SelectBox from './SelectBox';
import LanguageSelectBox from './LanguageSelectBox';
import MyBar from './MyBar';
import DurationBar from './DurationBar';
import LanguageBar from './LanguageBar';
import SwitchBox from './SwitchBox';

import {getTimeSpanData, getCountryList, getMultiCountryData, get_subjectMatterFirstLvl} from '../ressources/EurLexData';

const FILTER_WIDTH = 200;
//Width testing for widgets, delete after
const WIDGET_WIDTH = '29vw';


const MainPage = ({sendIsDarkModeToApp}) => {

    //Call reset() to trigger reload when needed
    const [seed, setSeed] = useState(1);
    const reset = () => {
        setSeed(Math.random());
    }

    //TabGroup active info 
    const [activeTab, setActiveTab] = useState(0);
    const sendActiveTabToPartent = (tab) => {
        setActiveTab(tab);
    };

    //Boolean which indicates if the checkbox for relation is active
    const [relation, setRelation] = useState(false);

    //Boolean which indicates if the General Court should be included
    const [includeEUG, setIncludeEUG] = useState(false);

    //Button country comparison
    const [comparison, setComparison] = useState(false);

    //Year min/max slider callback
    const [timeSpan, setTimeSpan] = useState([Globals.minYear, Globals.maxYear]);
    const sendTimeSpanToParent = (time) => {
        setTimeSpan(time);
    };

    //SelectBox chosen countries
    const defaultCountries = ['Germany', 'Spain', 'Poland', 'France', 'Netherlands']; //Third countries
    const [choosenCountries, setchoosenCountries] = useState(defaultCountries);
    const sendChoosenCountriesToPartent = (c) => {
      //console.log("Got a country change: "+JSON.stringify(choosen));
      let tmp = choosenCountries;
      tmp[c[0]] = c[1];
      setchoosenCountries(tmp);

      reset();
    };

    //SelectBox Language
    const [choosenLanguage, setchoosenLanguage] = useState("English");
    const sendChoosenLanguageToParent = (c) => {
      setchoosenLanguage(c);
      reset();
    };

    const[isDarkmode, setIsDarkMode] = useState(true);
    const sendIsDarkModeToParent = (isDark) => {
      console.log("MainPage: set isDarkt to "+isDark);
      setIsDarkMode(isDark);
      sendIsDarkModeToApp(isDark);
    }

    const styles = {
      filter_header: {
        fontSize: '1.125em',
        fontFamily: 'Arial',
        letterSpacing: '.32px',
        color: 'white'
      },
      filter_container: {
        height: '100%',
        width: FILTER_WIDTH,
        background: isDarkmode ? Colors.get().grey : Colors.get().almostWhite,
    },
    widget_container: {
        width: WIDGET_WIDTH,
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderColor: isDarkmode ? Colors.get().widget_header_dark : Colors.get().widget_header_light,
        borderRadius: 5,
        borderWidth: 3, 
        marginLeft: 10,
        marginTop: 5,
    },
    multiCountry_widget_container: {
      width: '90vw',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderColor: isDarkmode ? Colors.get().widget_header_dark : Colors.get().widget_header_light,
      borderRadius: 5,
      borderWidth: 3, 
      marginLeft: 10,
      marginTop: 5,
      background: isDarkmode ? Colors.get().grey : Colors.get().white,
    }
  };

  const DividerBar = () => {
    return (
      <div style={{height: 1, background: isDarkmode ? Colors.get().white : Colors.get().black, marginLeft: 5, marginRight: 5, marginTop: 10, marginBottom: 10}} />
    );
  }
  
  const WidgetHeader = ({title}) => {
    return (
      <div style={{height: 50, display: 'flex', alignItems: 'center', background: isDarkmode ? Colors.get().widget_header_dark : Colors.get().widget_header_light}} >
        <p style={{marginLeft: 10, fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', fontWeight: 'bold', color: isDarkmode ? Colors.get().white : Colors.get().black}} >
          {title}
        </p>
    </div>
    );
  }

  /* Used in MyBar to determine text color*/
  const lineGraphSettings = {
    theme: {
      fontSize: '12px',
      textColor: isDarkmode ? Colors.get().white : Colors.get().black,
    },
  };

  

  let data = getTimeSpanData(timeSpan[0], timeSpan[1]);
  let request_origin_country_OccFreq = getCountryList(data, relation, includeEUG); // "Court of Justice" , "General Court"

  let countries = request_origin_country_OccFreq[0];
  let country_freq = request_origin_country_OccFreq[1];
  let country_duration = request_origin_country_OccFreq[2];
  let multiData = getMultiCountryData(data, timeSpan, choosenCountries , choosenLanguage);
  console.log("Multicountry: "+JSON.stringify(multiData));
  let authentic_language = request_origin_country_OccFreq[3];

  let languages = request_origin_country_OccFreq[3];


  //let tmp = get_subjectMatterFirstLvl(data);

  return (
      !comparison ?
        <div style={{height: '90%', width: '100%', display: 'flex', flexDirection: 'row'}} >
            {/* Side Panel */}
            <div style={styles.filter_container} >
              <h3 style={{textAlign: 'center', fontSize: '1.125em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                Settings
              </h3>

              <DividerBar/>

              {/* Timespan selection */}
              <div className='centered-div' >
                <p style={{textAlign: 'center', fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', margin: 1, color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                  Use the Handles to change the time period
                </p>
                {/* Display the current timeSpan selection */}
                <p style={{textAlign: 'center', fontSize: '1.1em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                  {timeSpan[0]+' - '+timeSpan[1]}
                </p>
                {/* Slider to select which time period should be visualized with callBack function */}
                <div style={{marginLeft: 5, marginRight: 5}}>
                  <RangeSlider sendDataToParent={sendTimeSpanToParent} timeSpan={timeSpan} />
                </div>
              </div>

              <DividerBar/>

              {/* CheckBox relative population */}
              <div style={{marginTop: 15, marginLeft: 5}} >
                <input id='relCheck' onClick={() => setRelation(!relation)} type="checkbox" checked={relation ? 'checked' : ''} />
                <label htmlFor="vehicle1" style={{fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                  relative to population
                </label>
              </div>

              {/* CheckBox include 
              <div style={{marginTop: 15, marginLeft: 5}} >
                <input onClick={() => setIncludeEUG(!includeEUG)} type="checkbox"/>
                <label htmlFor="vehicle1" style={{fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                  Include General Court (EUG)
                </label>
              </div>
                */}

              <DividerBar/>

              {/* Button for switching views to country comparison */}
              <div style={{marginTop: 15, marginLeft: 5, justifyContent: 'center', display: 'flex'}} >
                <button style={{fontSize: 14, padding: 3, fontFamily: 'Arial', letterSpacing: '.32px'}} onClick={() => {setComparison(true); /*setTimeSpan([2010, 2021])*/}}>
                  View Country comparison
                </button>
              </div>

              {/* Toggle to switch between dark and light mode */}
              <div style={{marginTop: 880, marginLeft: 40}} >
                <div style={{position: 'fixed', bottom: '5.5%'}} >
                  <SwitchBox sendIsDarkModeToParent={sendIsDarkModeToParent} isDark={isDarkmode}/>
                </div>
              </div>
            </div>

            {/* MainPage Content (Map - Bar Graph) */}
            <div style={{display: 'flex', flexDirection: 'row', background: isDarkmode ? Colors.get().background : Colors.get().white}} >
                {/* The choropleth map */}
                <div style={styles.widget_container}>
                  <WidgetHeader title={'Geographical Frequency'}/>
                  <div style={{height: '100%'}} >
                    <MyChoropleth data={country_freq} isDarkMode={isDarkmode}/>
                  </div>
                </div>

                {/* Number court decisions - Bar Graph*/}
                <div style={styles.widget_container} >
                  <WidgetHeader title={'Number of court decisions'}/>
                  <div style={{height: '100%'}} >
                    <MyBar data={country_freq} which={'decision'} isDarkMode={isDarkmode} lineGraphSettings={lineGraphSettings}/>
                  </div>
                </div>

                {/* Duration cout proceedings - Bar Graph*/}
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}} >
                  <div style={styles.widget_container} >
                    <WidgetHeader title={'Authentic language in court decision'}/> {/* Duration of court proceedings */}
                    <div style={{height: '42vh'}} >
                      <LanguageBar data={authentic_language} isDarkMode={isDarkmode} lineGraphSettings={lineGraphSettings}/>
                    </div>
                  </div>

                  <div style={styles.widget_container} >
                    <WidgetHeader title={'Duration of court proceedings'}/> {/* Duration of court proceedings */}
                    <div style={{height: '42vh'}} >
                      <DurationBar data={country_duration} isDarkMode={isDarkmode} lineGraphSettings={lineGraphSettings}/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      :
        <div style={{height: '90%', width: '100%', display: 'flex', flexDirection: 'row'}}>
          {/* Side Panel */}
          <div style={styles.filter_container} >
            <h3 style={{textAlign: 'center', fontSize: '1.125em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
              Settings
            </h3>

            <DividerBar/>

            {/* Timespan selection */}
            <div className='centered-div' >
              <p style={{textAlign: 'center', fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', margin: 1, color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                Use the Handles to change the time period
              </p>
              {/* Display the current timeSpan selection */}
              <p style={{textAlign: 'center', fontSize: '1.1em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                {timeSpan[0]+' - '+timeSpan[1]}
              </p>
              {/* Slider to select which time period should be visualized with callBack function */}
              <div style={{marginLeft: 5, marginRight: 5}}>
                <RangeSlider sendDataToParent={sendTimeSpanToParent} timeSpan={timeSpan} />
              </div>
            </div>

            <DividerBar/>

            {/* Dropdown Menu for authentic_language selection */}
            <div style={{marginTop: 10, marginBottom: 20, marginLeft: 5, marginRight: 5}} >
                  <p style={{textAlign: 'center', fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                    Select a language to filter for
                  </p>
                  <LanguageSelectBox 
                    languages={languages} 
                    sendChoosenLanguageToParent={sendChoosenLanguageToParent} 
                    choosenLanguage={choosenLanguage}
                    isDarkMode={isDarkmode}
                  />
            </div>  

            <DividerBar/>

            {/* Dropdown Menu's for country selection */}
            <div style={{marginTop: 10, marginLeft: 5, marginRight: 5}} >
                  <p style={{textAlign: 'center', fontSize: '.8em', fontFamily: 'Arial', letterSpacing: '.32px', color: isDarkmode ? Colors.get().white : Colors.get().black}}>
                    Select up to 5 countries to compare
                  </p>
                  <SelectBox 
                    countries={countries} 
                    sendChoosenCountriesToPartent={sendChoosenCountriesToPartent} 
                    choosenCountries={choosenCountries}
                    isDarkMode = {isDarkmode}
                  />
            </div>  

            <DividerBar/>

            {/* Button for switching views to country comparison */}
            <div style={{marginTop: 15, marginLeft: 5, justifyContent: 'center', display: 'flex'}} >
              <button style={{fontSize: 14, padding: 3, fontFamily: 'Arial', letterSpacing: '.32px'}} onClick={() => {setComparison(false); /*setTimeSpan([2010, 2021])*/}}>
                View Country comparison
              </button>
            </div>

            {/* Toggle to switch between dark and light mode */}
            <div style={{marginTop: 880, marginLeft: 40}} >
                <div style={{position: 'fixed', bottom: '5.5%'}} >
                  <SwitchBox sendIsDarkModeToParent={sendIsDarkModeToParent} isDark={isDarkmode}/>
                </div>
            </div>
          </div>

          {/* MainPage Content (Map - Bar Graph) */}
          <div style={{display:'flex', background: isDarkmode ? Colors.get().background : Colors.get().white}} >
            <div style={styles.multiCountry_widget_container}>
              <WidgetHeader title={'Multicountry comparison'}/>
              <div style={{height: '100%', width: '100%'}} >
                <MultiCountryBar data={multiData} lineGraphSettings={lineGraphSettings}/>
              </div>
            </div>
          </div>
        </div>     
  );
}

export default MainPage;
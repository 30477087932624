class Colors {
    static get() {
        return {
            background: '#070707',
            lightGrey: '#AAAAAA',
            grey: '#19171a',
            barTint: '#998491',
            white_text: '#FFFFFF',
            widget_header_dark: '#333333',
            widget_header_light: '#DDDDDD',
            white: '#FFFFFF',
            almostWhite: '#EEEEEE',
            black: '#000000',
            widget_backg_dark: '#19171a',
            widget_backg_light: '#EEEEEE',
        }
    }
}

export default Colors;
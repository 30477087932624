//Originally scraped data
import scrapes from './scraped/scrapee.json';

//Contains german/english country names and their corresponding three letter code
//Population Data source: https://de.statista.com/statistik/daten/studie/1187610/umfrage/bevoelkerung-der-laender-europas/
import { countryLookup } from './countryLookup';

//##################################
//##      Exported functions      ##
//##################################

export function get_typeOfProcedure(data) {
    let typeCnt = [{type:"Action for annulment - unfounded", value: 0}];

    let entry = {type: "", value: 0};

    for (let i=0; i<data.length; i++) {
        let year = data[i];

        year.forEach((entry) => {

            let t_split = entry.typeOfProcedure.split(",");

            //Type not in typeCnt
            if (typeCnt.filter(e => e.type === t_split[0]).length == 0) {
                typeCnt.push({type: t_split[0], value: 0});
            //Type is already in typeCnt -> increase value
            }else {
                const entryy = (e) => e.type === t_split[0];
                typeCnt[typeCnt.findIndex(entryy)].value += 1;
            }

            //Traverse through split types and add em
            for (let k=1; k<t_split.length; k++) {
                let tmp = t_split[k].substring(1, t_split[k].length);
                //Add country after deleting leading space
                if (typeCnt.filter(e => e.type === tmp).length == 0) {
                    typeCnt.push({type: tmp, value: 0});
                }else {
                    const entryy = (e) => e.type === tmp;
                    typeCnt[typeCnt.findIndex(entryy)].value += 1;  
                }
            }
        });
    }

    console.log("That are my types: "+JSON.stringify(typeCnt));
}

export function get_subjectMatterFirstLvl(data) {
    let subjectMatterCnt = [];

   

    for (let i=0; i<data.length; i++) {
        let year = data[i];

        year.forEach((entry) => {

            let t_split = entry.author.split(",");
            for (let k=1; k<t_split.length; k++) { 
                t_split[k] = t_split[k].substring(1, t_split[k].length);
            }
            console.log("No leading spaces?!: "+JSON.stringify(t_split));

            //Traverse through split types and add em
            for (let k=0; k<t_split.length; k++) {
                //let tmp = t_split[k].substring(1, t_split[k].length);
                //Add country after deleting leading space
                if (subjectMatterCnt.filter(e => e.subject === t_split[k]).length == 0) {
                    subjectMatterCnt.push({subject: t_split[k], value: 0});
                }else {
                    const entryy = (e) => e.subject === t_split[k];
                    subjectMatterCnt[subjectMatterCnt.findIndex(entryy)].value += 1;  
                }
            }
        });
    }

    //Sort descending by mean value
    subjectMatterCnt.sort((a, b) => {
        return b.value - a.value;
    });

    console.log("That are my treaty: "+JSON.stringify(subjectMatterCnt));
}


export function getTimeSpanData(min, max) {
    let timespanData = [];

    for (let i=min; i<=max; i++) {
        timespanData.push(scrapes[i]);
    }
    
    //console.log("Final timeData: "+JSON.stringify(timespanData));
    return timespanData;
}

/**
 * Goes through all entries and collects unique countries 
 * which appear in the "request_origin" field of an entry
 * @param {all data from the selected time range} data 
 * @returns a list of unique country names which appear in
 *          the request_origin field
 */
export function getCountryList(data, relation) {
    let countryCnt = [];
    let countries = [];
    let countryDuration = [];
    let authenLangCnt = [];

    for (let i=0; i<data.length; i++) {
        let year = data[i];
        //If the filter for field "author" comes in uncomment this
        //if (court !== "")
        //    year = year.filter(e => e.author === court);
        //console.log("Entries left in year "+i+ ": "+year.length);
        //console.log("Year "+i+" entries: "+JSON.stringify(year));
        year.forEach((entry) => {
            if (entry.hasOwnProperty('Country_or_organisation_from_which_the_request_originates_')) {

                //For some reason some empty fields go through the !== null check, so check length as well                                                          && includeEUG ? true : entry.Author_ === "Court of Justice"
                if (entry.Country_or_organisation_from_which_the_request_originates_ !== null && entry.Country_or_organisation_from_which_the_request_originates_.length > 2 ) {
        
                    let c_split = entry.Country_or_organisation_from_which_the_request_originates_.split(",");
    
                    //The first one doesnt have a leading space so it can be added
                    if (!countries.includes(c_split[0])) {
                        countries.push(c_split[0]);
                        countryCnt.push(getEntry(c_split[0]));
                    }else { 
                        //If already in list, increase appearance counter by 1
                        const entry = (e) => e.country === c_split[0];
                        countryCnt[countryCnt.findIndex(entry)].value += 1;
                    }
    
                    //Traverse through split countries and add em
                    for (let k=1; k<c_split.length; k++) {
                        let tmp = c_split[k].substring(1, c_split[k].length);
                        //Add country after deleting leading space
                        if (!countries.includes(tmp)) {
                            countries.push(tmp);
                            countryCnt.push(getEntry(tmp));
                        }else {
                            //If already in list, increase appearance counter by 1
                            const entry = (e) => e.country === tmp;
                            countryCnt[countryCnt.findIndex(entry)].value += 1;   
                        }
                    }
    
                    if (entry.hasOwnProperty("Date_lodged_")) {
                        //Calculating duration per entry
                        if (entry.Date_lodged_ !== null && entry.Date_of_document_ !== null) {
        
                            let entry_date = new Date(entry.Date_lodged_.replace(/(.*)\/(.*)\/(.*)/, '$3-$2-$1'));
                            //console.log("JSON entry Date: "+entry.entry_date);
                            //console.log("Entry Date: "+entry_date);
                            let date_of_doc = new Date(entry.Date_of_document_.replace(/(.*)\/(.*)\/(.*)/, '$3-$2-$1'));
                            //console.log("JSON doc Date: "+entry.date_of_document);
                            //console.log("Document Date: "+date_of_doc);
        
                            // The unit is millisecond
                            let duration = date_of_doc.valueOf() - entry_date.valueOf(); 
                            // Turn the duration into hour format
                            let hourDiff = parseInt(duration / (60 * 60 * 1000));
                            //Turn hour format into year
                            let yearDiff = parseFloat((hourDiff / 24) / 365); 
        
                            //Insert yearDiff into corresponding countries
                            for (let j=0; j<c_split.length; j++) {
                                //Get the country name
                                let c = null;
                                if (j === 0) {
                                    c = c_split[j];
                                }else {
                                    c = c_split[j].substring(1, c_split[j].length);
                                }
                                
                                //Get entry for duration
                                let entry = getDurationEntry(c);
                                //Check if entry is already in array
                                const check = (e) => e.country === c;
                                let index = countryDuration.findIndex(check);
                                
                                if (index === -1) {
                                    //Entry is not in so push it in
                                    countryDuration.push(entry);
                                    countryDuration[countryDuration.indexOf(entry)].values.push(parseFloat(yearDiff));
                                }else {
                                    //Push next yearDiff to country
                                    countryDuration[index].values.push(parseFloat(yearDiff));
                                }
                            }
                        }
                    
                    }
                    
                }


                if (entry.hasOwnProperty("Authentic_language_")) {
                    //Gathering counting countries appearance in "authentic_language" field
                    if (entry.Authentic_language_ !== null && entry.Authentic_language_.length > 2) {
                        let lang_split = entry.Authentic_language_.split(",");
        
                        if (!isLanguageInsideAuthLanguage(lang_split[0], authenLangCnt)) {
                            authenLangCnt.push(getEntry_AuthenticLanguage(lang_split[0]));
                        }else {
                            //If already in list, increase appearance counter by 1
                            const entry = (e) => e.country === lang_split[0];
                            authenLangCnt[authenLangCnt.findIndex(entry)].value += 1;
                        }
        
                        for (let k=1; k<lang_split.length; k++) {
                            let tmp = lang_split[k].substring(1, lang_split[k].length);
                            if (!isLanguageInsideAuthLanguage(tmp, authenLangCnt)) {
                                authenLangCnt.push(getEntry_AuthenticLanguage(tmp));
                            }else {
                                //If already in list, increase appearance counter by 1
                                const entry = (e) => e.country === tmp;
                                authenLangCnt[authenLangCnt.findIndex(entry)].value += 1;
                            }
                        }   
                    }
                }else {
                    //console.log("Authentic language not there");
                }
            }else {
                //console.log("Field country_origin not there");
            }


        });
    }

    authenLangCnt.sort((a, b) => {
        return a.value - b.value;
    });

    //console.log("Authentic Language: "+JSON.stringify(authenLangCnt));
    
    //Calc mean duration for all countries
    countryDuration.forEach((entry) => {
        let mean = parseFloat(entry.values.reduce((a, b) => a + b) / entry.values.length).toFixed(2);
        entry.value = mean;
        //console.log("Country: "+entry.country+"\t has mean duration of: "+mean);
    });

    //Sort descending by mean value
    countryDuration.sort((a, b) => {
        return a.value - b.value;
    });
    
    if (relation) {
        for (let i=0; i<countryCnt.length; i++) {
            let pop = getPopulation(countryCnt[i].country);
            //console.log(i+" Country: "+countryCnt[i].country+"\nPop: "+pop);
            //If pop == null there no relation to pop can be performed so get rid of it
            //If pop !== null divide case amount by pop (pop is in mio)
            if (pop !== null)
                countryCnt[i].value = (countryCnt[i].value / pop).toFixed(2);
            else {
                countryCnt.splice(i, 1);
                //Decrement index to not skip an entry, bc we just deleted one and index shifted
                i -= 1;
            }
        }
    }
    
    //Sort descending by entry.value
    countryCnt.sort((a, b) => {
        return a.value - b.value;
    });

    //console.log("List of single countries: \n"+JSON.stringify(countryCnt));
    return [countries, countryCnt, countryDuration, authenLangCnt];
}

export function getMultiCountryData(data, timeSpan, choosenCountries, language) {
    let multiData = {};
    let yearMin = timeSpan[0];
    let yearMax = timeSpan[1];
    
    let keys = [];
    for (let i=yearMin; i<=yearMax; i++) {
        keys.push(i);
    }
    
    multiData['keys'] = keys;
    multiData['countries'] = [];
    //console.log("Countries recieved: "+choosenCountries);
    let defaultCountries = choosenCountries; //['Germany', 'Spain', 'Poland', 'France', 'Third countries'];
    defaultCountries.forEach((c) => {
        multiData['countries'].push({"country": c});
    });

    for (let i=0; i<data.length; i++) {
        let intYear = parseInt(yearMin+i);
        let year = data[i];
        let yearData = getYearCountryData(year, language);
        
        let js = {entry: {}}
        
        yearData.forEach((y) => {
            for (let k=0; k<multiData['countries'].length; k++) {
                //console.log("????:   "+JSON.stringify(multiData['countries'][k]));
                if (multiData['countries'][k].country === y.country) {
                    multiData['countries'][k][intYear] = y.value;
                }   
            }
        });

    }
    //Debug print
    //console.log("MultiData; "+JSON.stringify(multiData));

    return multiData;
}

//##################################
//##   intern/helper functions    ##
//##################################


function getYearCountryData(year, language) {
    let countryCnt = [];
    let countries = [];
    if (language !== "All") {
        year = year.filter(e => e.Authentic_language_ === language);
    }
    year.forEach((entry) => {
        if (entry.hasOwnProperty('Country_or_organisation_from_which_the_request_originates_')) {
            //For some reason some empty fields go through the !== null check, so check length as well
            if (entry.Country_or_organisation_from_which_the_request_originates_ !== null && entry.Country_or_organisation_from_which_the_request_originates_.length > 2) {
    
                let c_split = entry.Country_or_organisation_from_which_the_request_originates_.split(",");
    
                //The first one doesnt have a leading space so it can be added
                if (!countries.includes(c_split[0])) {
                    countries.push(c_split[0]);
                    countryCnt.push(getEntry(c_split[0]));
                }else { 
                    //If already in list, increase appearance counter by 1
                    const entry = (e) => e.country === c_split[0];
                    countryCnt[countryCnt.findIndex(entry)].value += 1;
                }
    
                //Traverse through split countries and add em
                for (let k=1; k<c_split.length; k++) {
                    let tmp = c_split[k].substring(1, c_split[k].length);
                    //Add country after deleting leading space
                    if (!countries.includes(tmp)) {
                        countries.push(tmp);
                        countryCnt.push(getEntry(tmp));
                    }else {
                        //If already in list, increase appearance counter by 1
                        const entry = (e) => e.country === tmp;
                        countryCnt[countryCnt.findIndex(entry)].value += 1;   
                    }
                }
            }
        }
    });

    return countryCnt;
}


function getDurationEntry(_country) {
    let _letterCode = null;

    countryLookup.forEach((country) => {
        if (country.country_en === _country) {
            _letterCode = country.threeLetterCode;  
        }
    });
    
    //Build the entry with both real name, threeLetter code and a counter for occurences   
    let entry = {id: _letterCode, country: _country, values: [], value: -1.1};

    return entry;
}

function getEntry(_country) {
    let _letterCode = null;

    countryLookup.forEach((country) => {
        if (country.country_en === _country) {
            _letterCode = country.threeLetterCode;  
        }
    });
    
    //Build the entry with both real name, threeLetter code and a counter for occurences   
    let entry = {id: _letterCode, country: _country, value: 1};

    return entry;
}

function getEntryWithCourt(_country, _court) {
    let _letterCode = null;

    countryLookup.forEach((country) => {
        if (country.country_en === _country) {
            _letterCode = country.threeLetterCode;  
        }
    });
    
    //Build the entry with both real name, threeLetter code and a counter for occurences   
    let entry = {id: _letterCode, country: _country, value: 1, court: _court};

    return entry;
}

function getEntry_AuthenticLanguage(language) {
    return {country: language, value: 1};
}

function isLanguageInsideAuthLanguage(_lang, authLangCnt) {
    let inside = false;
    authLangCnt.forEach((entry) => {
        if (entry.country === _lang)
            inside = true;
    });
    return inside;
}

function getPopulation(_country) {
    const entry = (e) => e.country_en === _country;
    let index = countryLookup.findIndex(entry);
    //console.log("Country: "+_country+"\nIndex: "+index);
    if (index !== -1) {
        return countryLookup[countryLookup.findIndex(entry)].inhabitants;
    }
    return null;
}